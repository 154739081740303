<template>
    <div class="row">
        <div class="col-12">
            <label class="form-label">Değer</label>
            <input class="form-control" type="text" v-model="value.Value"/>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        value:Object
    }
}
</script>

<style>

</style>