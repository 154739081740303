<template>
    <b-card no-body>

        <b-modal id="update" :title="['Güncelle',param.VisibleName || null].join(' - ')" no-close-on-esc no-close-on-backdrop>
            <system-parameter-update v-model="param"></system-parameter-update>
            <template #modal-footer><v-executer :action="update" text="Güncelle" :loading="loading"></v-executer></template>
        </b-modal>

        <!-- <v-table    ref="table"
                    with-items
                    :items="params"
                    search
                    :settings=false
                    :fields="fields"
                    :buttons="buttons"></v-table> -->

        <b-overlay :show="loading" opacity="0.2" rounded="sm" :class="!params.length ? 'p-3' : 'p-1'">
            <div v-for="(item,index) in params" :key="index" :class="index != 0 ? 'mt-1' : null">
                <div class="alert alert-dark mb-0">
                    <div class="alert-body px-50 py-25">
                        <div class="row align-items-center p-25">
                            <div class="col">
                                {{item.GroupCode}}
                            </div>
                            <div class="col-auto">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <v-tree-view ref="treeViews" v-model="item.SystemParameters" >
                    <template #below-data="{data}">
                        <table v-if="item.SystemParameters.length" class="table table-sm table-bordered mt-50 mb-25">
                            <div class="row align-items-center pl-25">
                                <div class="col">
                                    <span>{{data.VisibleName}}</span> - <span class="text-muted">{{data.Description}}</span>
                                </div>
                                <div class="col-auto text-right">
                                    <b-badge>{{ data.Value }}</b-badge>
                                    <button class="btn btn-sm btn-icon btn-flat-secondary ml-25 mr-25" v-on:click="edit(data)">
                                        <feather-icon icon="Edit2Icon"></feather-icon>
                                    </button>
                                </div>
                            </div>
                        </table>
                    </template>
                  
                </v-tree-view>
            </div>
        </b-overlay>

    </b-card>
</template>

<script>
import { SystemParameterApi } from '@/api/system-parameter'
import VTreeView from '@/components/v-tree-view/VTreeView'
import { BCard,BOverlay,BBadge,BFormCheckbox } from 'bootstrap-vue'
import Parameters from '@/utilities/parameters'
import SystemParameterUpdate from './components/SystemParameterUpdate.vue'

export default {
    components:{
        BCard,
        BFormCheckbox,
        BOverlay,
        VTreeView,
        BBadge,
        SystemParameterUpdate
    },
    data() {
        return {
            loading:false,
            fields:[
                {name:'Anahtar',key:'VisibleName',thClass:'w-50'},
                {name:'Değer',key:'Value',thClass:'w-50'},
            ],
            buttons:[
                { text:"Düzenle",key:"edit",icon:"Edit2Icon",variant:"secondary",action:this.edit }
            ],
            param:{
                Id:null,
                VisibleName:null,
                Value:null
            }
        }
    },
    methods:{
        clearForm(){
            this.param.Id = null
            this.param.VisibleName = null
            this.param.Value = null
        },
        edit(item){
            this.clearForm()
            this.param.Id = item.Id
            this.param.VisibleName = item.VisibleName
            this.param.Value = item.Value
            this.param.Key = item.Key
            this.$showModal('update')
        },
        update(){
            this.loading = true
            SystemParameterApi.Update(this.param).then(res=>{
                this.$result(res)
                this.loading = false
                if(res.data.Success){
                    Parameters.loadParameters()
                    this.$hideModal('update')
                }
            }).catch(err=>{
                this.loading = false
                this.$result(null,err)
            })
        },
        
    },
    computed:{
        params(){
            return Parameters.parameters
        }
    }
}
</script>

<style>

</style>