<template>
    <div class="tree-view-container">
        <div class="tree-view">
            <v-tree-view-item v-for="(val,index) in value" v-model="value[index]" :key="index" :all-collapsed="allCollapsed" :last="index == value.length - 1">
                <template #data="{ data }">
                    <slot name="data" :data="data"></slot>
                </template>
                <template #below-data="{ data }">
                    <slot name="below-data" :data="data"></slot>
                </template>
            </v-tree-view-item>
        </div>
    </div>
</template>

<script>
import VTreeViewItem from '@/components/v-tree-view/VTreeViewItem'
export default {
    name:"v-tree-view",
    components:{
        VTreeViewItem
    },
    data(){
        return{
            allCollapsed:0,
        }
    },
    props:{
        value:{
            type:Array,
            default:function(){
                return {};
            },
        },
    },
    methods:{
        collapseAll(){
            this.allCollapsed += 1
        },
    }
}
</script>

<style>
    .tree-view {
        margin-left: 20px;
    }

    .tree-view-container .tree-view-item {
        margin: 10px 0 0px 10px;
        position: relative;
    }
    .tree-view-container  .tree-view-item:before {
        content: "";
        position: absolute;
        top: -10px;
        left: -20px;
        border-left: 2px solid #ebe9f1;
        border-bottom: 2px solid #ebe9f1;
        width: 20px;
        height: 20px;  
        transition: border 0.2s ease-in-out;
    }

    .dark-layout .tree-view-container  .tree-view-item:before{
        border-left-color: hsl(255, 22%, 50%);
        border-bottom-color: hsl(255, 22%, 50%);
    }

    .tree-view-container  .tree-view-item.last:before {
        border-radius: 0 0 0 5px;
    }

    .tree-view-container  .tree-view-item:after {
        position: absolute;
        content: "";
        top: 10px;
        left: -20px;
        border-left: 2px solid #ebe9f1;
        width: 20px;
        height: 100%;  
        transition: border 0.2s ease-in-out;      
    }


     @media (max-width: 768px) {
        .tree-view-container .tree-view-item:before {
            width: 10px;
            left: -10px;
        }
        .tree-view-container .tree-view-item:after {
            width: 10px;
            left: -10px;
        }
        .tree-view {
            margin-left: 10px;
        }
    }

    .dark-layout .tree-view-container  .tree-view-item:after {
        border-left-color: hsl(255, 22%, 50%);
    }

    .tree-view-container  .tree-view-item:last-child:after {
        display: none;
    }

    .tree-view-container  .tree-view-item  .tree-view-content {
        border-radius: 5px;
        padding: 10px;
        transition: border 0.3s ease-in-out;
        border: 1px solid  #ebe9f1;
    }

    .dark-layout  .tree-view-container  .tree-view-item  .tree-view-content {
        border-color: hsl(255, 22%, 50%);
    }

    .tree-view-container  .tree-view-item .tree-view-content:hover, .tree-view-container .tree-view-item .tree-view-content:focus {
        border-color: hsl(255, 22%, 75%) !important;
    }
    .tree-view-container  .tree-view-item .tree-view-content:hover + .collapse .tree-view  .tree-view-item  .tree-view-content, 
    .tree-view-container  .tree-view-item .tree-view-content:focus + .collapse .tree-view  .tree-view-item  .tree-view-content {
       border-color: hsl(255, 22%, 75%) !important;
    }
    .tree-view-container  .tree-view-item .tree-view-content:hover + .collapse .tree-view .tree-view-item:after, 
    .tree-view-container  .tree-view-item .tree-view-content:hover + .collapse .tree-view  .tree-view-item:before,
    .tree-view-container  .tree-view-item .tree-view-content:focus + .collapse .tree-view  .tree-view-item:after, 
    .tree-view-container  .tree-view-item .tree-view-content:focus + .collapse .tree-view  .tree-view-item:before {
       border-color: hsl(255, 22%, 75%) !important;
    }

    
    .dark-layout .tree-view-container  .tree-view-item .tree-view-content:hover, .tree-view-container .tree-view-item .tree-view-content:focus {
        border-color: hsl(255, 22%, 95%) !important;
    }
    .dark-layout .tree-view-container  .tree-view-item .tree-view-content:hover + .collapse .tree-view  .tree-view-item  .tree-view-content, 
    .dark-layout .tree-view-container  .tree-view-item .tree-view-content:focus + .collapse .tree-view  .tree-view-item  .tree-view-content {
       border-color: hsl(255, 22%, 95%) !important;
    }
    .dark-layout .tree-view-container  .tree-view-item .tree-view-content:hover + .collapse .tree-view .tree-view-item:after, 
    .dark-layout .tree-view-container  .tree-view-item .tree-view-content:hover + .collapse .tree-view  .tree-view-item:before,
    .dark-layout .tree-view-container  .tree-view-item .tree-view-content:focus + .collapse .tree-view  .tree-view-item:after, 
    .dark-layout .tree-view-container  .tree-view-item .tree-view-content:focus + .collapse .tree-view  .tree-view-item:before {
       border-color: hsl(255, 22%, 95%) !important;
    }

    .tree-view-collapser{
        height: 20px;
        width: 20px;
        min-width: 20px;
        background-color: hsl(255, 22%, 97%);
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s ease;
    }

    .dark-layout .tree-view-collapser{
        background-color: hsl(255, 22%, 30%);
    }

    .tree-view-collapser.show{
        transform: rotate(90deg);
    }

    .tree-view-collapser .feather{
        height: 16px;
        width: 16px;
    }
</style>