<template>
    <div class="tree-view-item" :class="last ? 'last' : null">
        <div class="tree-view-content shadow-sm pt-25 pb-25">
            <div class="d-flex align-items-center">
                <div v-if="hasChildren"  v-on:click="isShow = !isShow" class="mr-50 tree-view-collapser" :class="isShow ? 'show':'hide'">
                    <feather-icon icon="ChevronRightIcon"></feather-icon>
                </div>
                <div class="w-100">
                    <slot name="data" :data="value"></slot>
                </div>
            </div>
            <div>
                <slot name="below-data" :data="value"></slot>
            </div>
        </div>
        
        <template v-if="hasChildren">
            <b-collapse v-model="isShow">
                <div class="tree-view">
                    <v-tree-view-item v-for="(child,index) in value.Children" v-model="value.Children[index]" :last="index == value.Children.length - 1" :key="index" :depth="depth + 1" >
                        <template #data="{ data }">
                            <slot name="data" :data="data"></slot>
                        </template>
                        <template #below-data="{ data }">
                            <slot name="below-data" :data="data"></slot>
                        </template>
                    </v-tree-view-item>
                </div>
            </b-collapse>
        </template>
    </div>
</template>

<script>
import {BCollapse} from 'bootstrap-vue'
export default {
    name:"v-tree-view-item",
    components:{
        BCollapse
    },
    props:{
        value:{
            type:Object,
            default:function(){
                return {};
            },
        },
        last:{
            type:Boolean,
            default:0
        },
        depth:{
            type:Number,
            default:1
        },
        allCollapsed:{
            type:Number,
            default:0
        }
    },
    watch:{
        allCollapsed(){
            this.isShow = false
        }
    },
    data(){
        return{
            isShow:true
        }
    },
    computed:{
        hasChildren(){
            return this.value.Children && this.value.Children.length > 0
        }
    }
}
</script>

<style>
    
</style>